import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_REGION = false;
export default {
    async dummyDataREGION(){
        return  new Promise((resolve) => {
            var datas = [
                {key : "1", label:"ME_45", values: "ME 45"},
                {key : "2", label:"ME_48", values: "ME 48"},
                {key : "3", label:"FKIKLIM71", values: "FKIKLIM71"},
                {key : "4", label:"KELEMBAPAN_TANAH", values: "KELEMBAPAN TANAH"},
                {key : "5", label:"IKLIM_MIKRO", values: "IKLIM MIKRO"},
                {key : "6", label:"SUHU_TANAH", values: "SUHU TANAH"},
                {key : "7", label:"OP_PENGUAPAN", values: "OP PENGUAPAN"},
                {key : "8", label:"PSYCHROMETER_ASSMAN", values: "PSYCHROMETER ASSMAN"},
                {key : "9", label:"LYSIMETER", values: "LYSIMETER"},
                {key : "10", label:"PICHE_PENGUAPAN", values: "PICHE PENGUAPAN"},
                {key : "11", label:"GUNBELLANI", values: "GUNBELLANI"},
                {key : "12", label:"LAMA_PENYINARAN", values: "LAMAPENYINARAN"},
                {key : "13", label:"THERMOHIGOGRAPH", values: "THERMOHIGOGRAPH"},
                {key : "14", label:"AKTINOGRAPH", values: "AKTINOGRAPH"},
                {key : "15", label:"FORM_HUJAN", values: "FORM HUJAN"},
                {key : "16", label:"BAROGRAPH", values: "BAROGRAPH"}
            ];
            resolve(datas);
        });
    },

    async storeDataREGION(){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_REGION();
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataREGION(){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_REGION == true){
                var datas = await this.dummyDataREGION();
                resolve(datas);
            }else{
                var thisData = await this.storeDataREGION();
                var datas = thisData.data;
                resolve(datas);
            }
        });
    }
}