<template>
  <div>
    <b-card>
      <b-row>
        <!-- ==start-content-left== -->
        <b-col sm="12">
          <div class="header-box-custome">Form Filter Visualisasi</div>
        </b-col>
        <b-col sm="12">
          <a-spin :spinning="LOADING_PAGE">
            <b-row class="">
              <b-col cols="2">
                  <label>*Bulan Awal</label>
                  <flat-pickr  v-model="start_date" style="height: 39px" class="form-control" :config="dpconfig" />
                </b-col>
                <b-col cols="2">
                  <label>*Bulan Akhir</label>
                  <flat-pickr  v-model="end_date" style="height: 39px" class="form-control" :config="dpconfig" />
                </b-col>
                <b-col sm="4">
                  <label>*Form</label>
                  <v-select v-model="t_form" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                    :options="t_form_opt"  />
                </b-col>
                <b-col sm="3">
                  <label>*Parameter</label>
                  <v-select v-model="t_parameter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                    :options="t_parameter_opt"  />
                </b-col>
                
            </b-row>
            <b-row style="padding-top: 10px;">
              <b-col sm="3">
                  <label>*Balai</label>
                  <v-select v-model="t_balai" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                    :options="t_balai_opt"  />
                </b-col>
                <b-col sm="4">
                  <label>Provinsi</label>
                  <v-select v-model="t_provinsi" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                    :options="t_provinsi_opt"  />
                </b-col>
                <b-col sm="4">
                  <label>Stasiun</label>
                  <v-select v-model="t_stasiun" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                    :options="t_stasiun_opt"  />
                </b-col>
              <b-col cols="1 " style="text-align: right;padding-top: 5px;">
                <label>&nbsp;</label>
                <a-button type="primary" icon="search" @click="onSearchReport"> Search </a-button>
              </b-col>
            </b-row>
          </a-spin>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row>
        <!-- ==start-content-left== -->
        <b-col sm="12">
          <div class="header-box-custome">VIEW VISUALITATION</div>
        </b-col>
        <b-col sm="12">
          
        </b-col>
      </b-row>
    </b-card>
    <!-- STARTMODAL MARKER VIEW -->
    
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BListGroup,
  BListGroupItem,
  BCard,
  BCardHeader,
  BCardTitle,
  BTabs,
  BTab,
  BFormDatepicker,
  BAvatar,
  BBadge,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'


import DataRESOURCE from "@/api/dummy/store/DataDummyRESOURCE";
import DataREGION from "@/api/dummy/store/DataDummyREGION";
import DataPROVINCE from "@/api/dummy/store/DataDummyPROVINCE";
import DataKABUPATEN from "@/api/dummy/store/DataDummyKABUPATEN";
import DataDummyRAWVISUAL from "@/api/dummy/store/DataDummyRAWVISUAL";
import DataHelper from "@/api/dummy/store/DataHelper";
import moment from "moment";
import "leaflet/dist/leaflet.css";

import DinamicTable from "./DinamicTable.vue";
import DinamicMaps from "./DinamicMaps.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    DinamicTable,
    DinamicMaps,
    flatPickr,
    vSelect

  },
  methods: {
    async onLoadResource() {
      
      this.LOADING_PAGE = false;
    },
    
  },
  data() {
    return {
      dpconfig: {
        plugins: [
            new monthSelectPlugin({
              shorthand: true,
              dateFormat: "m/Y",
            })
          ]
      },
      t_form: "",
      t_form_opt: [
      { value: "MetarSpeci", text: "MetarSpeci" },
        { value: "Pibal", text: "Pibal" },
        { value: "sinoptik", text: "Sinop" },
        { value: "Aerosol", text: "Aerosol" },
        { value: "Agm1a", text: "Agm1a" },
        { value: "Agm1b", text: "Agm1b" },
        { value: "Aktinograph", text: "Aktinograph" },
        { value: "Barograph", text: "Barograph" },
        { value: "Fenologi", text: "Fenologi" },
        { value: "Fklim", text: "Fklim 71" },
        { value: "FormHujan", text: "Form Hujan" },
        { value: "FormPerawanan", text: "Form Perawanan" },
        { value: "Gunbellani", text: "Gunbellani" },
        { value: "IklimMikro", text: "Iklim Mikro" },
        { value: "HujanHellman", text: "Intensitas Hujan(Hellman)" },
        { value: "Kah", text: "Kimia Air Hujan" },
        { value: "KelembabanTanah", text: "Kelembaban Tanah" },
        { value: "LamaPenyinaran", text: "Lama Penyinaran" },
        { value: "Lysimeter", text: "Lysimeter" },
        { value: "OpPenguapan", text: "OP Penguapan" },
        { value: "PichePenguapan", text: "Piche Penguapan" },
        { value: "PsycrometerAssman", text: "Psychrometer Assman" },
        { value: "So2no2", text: "SO2NO2" },

        { value: "SuhuTanah", text: "Suhu Tanah" },
        { value: "Spm", text: "Suspended Particulate Matter" },
        { value: "Thermohigograph", text: "Thermohigrograph" },
      ],
      
    LOADING_PAGE: true,
      
    };
  },
  mounted: function () {
    this.onLoadResource();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style>
.header-box-custome {
  background: #3f9cff;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
}

.monitor .vgt-table {
  font-size: 12px !important;
}

[dir="ltr"] .monitor .vgt-table th {
  padding: 5px 25px 5px 5px !important;
}

[dir] .monitor table.vgt-table td {
  padding: 5px 25px 5px 5px !important;
}
</style>
