import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_PROVINCE = false;
export default {
    async dummyDataPROVINCE(){
        return  new Promise((resolve) => {
            var datas = [
                {"region_id":"1","region_description":"Region I","propinsi_id":"1","propinsi_name":"Nanggroe Aceh Darussalam","propinsi_code":"11","id":"1","path":"/1/1"},
                {"region_id":"1","region_description":"Region I","propinsi_id":"10","propinsi_name":"Kep. Riau","propinsi_code":"21","id":"10","path":"/1/10"},
            ]   
            resolve(datas);
        });
    },

    async storeDataPROVINCE(REGION){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_PROVINCE(REGION);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataPROVINCE(REGION){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_PROVINCE == true){
                var datas = await this.dummyDataPROVINCE();
                resolve(datas);
            }else{
                var thisData = await this.storeDataPROVINCE(REGION);
                var datas = thisData.data;
                resolve(datas);
            }
        });
    },

    async storeDataALLPROVINCE(){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_ALL_PROVINCE();
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataAllPROVINCE(){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_PROVINCE == true){
                var datas = await this.dummyDataPROVINCE();
                resolve(datas);
            }else{
                var thisData = await this.storeDataALLPROVINCE();
                var datas = thisData.data;
                resolve(datas);
            }
        });
    }
}