import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_KABUPATEN = false;
export default {
    async dummyDataKABUPATEN(){
        return  new Promise((resolve) => {
            var datas = [
                {"region_id":"1","region_description":"Region I","propinsi_id":"1","propinsi_name":"Nanggroe Aceh Darussalam","propinsi_code":"11","id":"1","path":"/1/1"},
                {"region_id":"1","region_description":"Region I","propinsi_id":"10","propinsi_name":"Kep. Riau","propinsi_code":"21","id":"10","path":"/1/10"},
            ]   
            resolve(datas);
        });
    },

    async storeDataKABUPATEN(PROVINCE){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_KABUPATEN(PROVINCE);
            var data = axios.get(url, Helper.getConfig())
            .then(function (dataResponse) {
                resolve(dataResponse);
            })
            .catch(function (error) {
                resolve({success : false, message : error});
            });
        });
    },

    async callDataKABUPATEN(PROVINCE){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_KABUPATEN == true){
                var datas = await this.storeDataKABUPATEN();
                resolve(datas);
            }else{
                var thisData = await this.storeDataKABUPATEN(PROVINCE);
                var datas = thisData.data;
                resolve(datas);
            }
        });
    }
}